<template>
    <ui-select
        class="v3-course-picker"
        :options="options"
        v-model="innerValue"
        @input="$emit('input', innerValue)"
        :placeholder="placeholder"
        :multiple="multiple"
        :disabled="disabled || !options.length"
    ></ui-select>
</template>

<script>
import useApi from '@/modules/api/mixins/useApi'
import apiCourses from '@/modules/v3/api/courses'

import {UiSelect} from '@/modules/ui/components'

export default {
    name: "v3-course-picker",
    mixins: [useApi],
    api: apiCourses,

    components: {
        UiSelect,
    },

    props: {
        value: {},

        placeholder: {
            type: String,
            required: false,
            default: ''
        },

        yearId: {
            type: String,
            required: true
        },

        multiple: {
            type: Boolean,
            default: false
        },

        extra: {
            type: Object,
            required: false,
            default: null
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false,
        }
    },

    data() {
        return {
            innerValue: null,
            isLoading: false,
            courses: []
        }
    },

    computed: {
        options() {
            return this.courses.map(course => {
                return {
                    value: course.id,
                    text: course.name,
                    secondary: course.category.name,
                };
            });
        },
    },

    methods: {
        async fetchCourses() {
            this.isLoading = true;
            this.courses = await this.$api.getCourses(this.yearId, this.extra);
            this.$emit('fetched', this.courses)
            this.isLoading = false;
        }
    },

    watch: {
        value: {
            immediate: true,
            handler(newValue) {
                this.innerValue = newValue;
            }
        },

        yearId: {
            immediate: true,
            handler() {
                this.fetchCourses();
            }
        }
    }
}
</script>