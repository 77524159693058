<template>
	<div class="ui-calendar-academic-schedule-week">
		<div class="row days">
			<div class="header-hours-vacio"></div>
			<div
				class="week"
				v-for="day in daysOfWeek"
				:key="day.key"
				valign="top"
				:class="['ui-calendar-day', day.classnames]"
				:style="[weekEnd ? { width: '13.571%' } : { width: '19%' }]"
			>
				<div class="ui-calendar-day-header label-day-header" :style="day.today ? [{backgroundColor: defaultColor}, {boxShadow: '0 10px 25px ' + customShadow }] : ''" @click="clickDay(day)">
					<span class="label-weekday">{{ $date(day.date, 'EEE') }}</span>
					<span v-if="!hideLabelDay" class="label-day" :style="day.today ? {color: defaultColor} : ''">{{day.date.getDate()}}</span>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="header-hours gmt">GMT-05</div>
			<div
				class="week"
				v-for="day in daysOfWeek"
				:key="day.key"
				valign="top"
				:style="[weekEnd ? { width: '13.571%' } : { width: '19%' }]"
			>
				<div class="event-total"></div>
			</div>
		</div>

		<!-- droppable element -->
		<div class="row target">
			<div class="header-hours" id="all-header-hours">
				<div class="label-hours" v-for="(hours, h) in hoursCalendar" :key="h">
					<span v-if="hours.hour > 0">{{ hours.hour }} {{ hours.time }}</span>
				</div>
			</div>
			<div
				class="week"
				v-for="day in daysOfWeek"
				:key="day.key"
				valign="top"
				:style="[weekEnd ? { width: '13.571%' } : { width: '19%' }]"
			>
				<div 
					class="event-total"
					id="event-total"
					v-for="(hours, h) in hoursCalendar" 
					:key="h" 
					@dragover.prevent
					@dragleave.prevent="offDropZone($event)"
					@dragenter.prevent="onDropZone($event)"
					@drop.prevent="onDrop($event, day.key, h)"
				>
					<!-- BEGIN: Horario académico  -->
					<div class="ui-calendar-day-contents">
						<!-- <transition name="slide"> -->
							<span v-if="hourEvents[day.dayNumber]">
								<div v-for="(event, i) in hourEvents[day.dayNumber]" :key="i" :class="['ui-calendar-event-custom', event.className]">
									<!-- BEGIN: Componente eventos -->
									<ui-calendar-events
										v-if="hours.key == event.startHour"
										ref="calendarEvents"
										:day="day.dayNumber"
										:hour="hours.key"
										:event="event"
										:sizeEvent="sizeEvent"
										:allowToDeleteClasses="allowToDeleteClasses"
										:allowMoveSessions="allowMoveSessions"
										:showCurrentTime="showCurrentTime"
										:useBackgroundImages="useBackgroundImages"
										:defaultColor="defaultColor"
										:additional-teachers="additionalTeachers"
										@click-event="$emit('click-event', $event)"
										@delete-event="$emit('delete-event', $event)"
										@drag-start="onDragStart($event, event)"
										@drag-end="onDragEnd($event, event)"
									></ui-calendar-events>
									<!-- END: Componente eventos -->
								</div>
							</span>
						<!-- </transition> -->
					</div>
					<!-- END: Horario académico  -->
				
					<!-- BEGIN: Linea para identificar la hora actual " -->
					<div v-if="markCurrentTime(day.dayNumber, hours.key)" class="line-marker" :style="[{top: minutePosition + 'px' }]">
						<span class="point-marker"></span>
					</div>
					<!-- END: Linea para identificar la hora actual " -->

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import UiCalendarEvents from './AcademicEvents.vue';

export default {
	name: 'ui-calendar-academic-schedule-week',
	mixins: [useI18n],
	components: {UiCalendarEvents},

	data() {
		return {
			dayCellSize: 46,
			sizeEvent: [],
            intervalMinute: null,
			minutePosition: 0,
			innerStarHour: this.startHour,
			innerEndHour: this.endHour,
		};
	},

	watch: {
		showCurrentTime: {
			deep: true,
			immediate: true,
			handler: function(curentVal) {
				if (curentVal) {
					this.intervalMinute = setInterval(()=>{
						let current = new Date();
						this.minutePosition = (this.dayCellSize * current.getMinutes()) / 59;
					}, 60000);
				} else {
					clearInterval(this.intervalMinute);
				}
			}
		},
	},

	// https://learnvue.co/tutorials/vue-drag-and-drop
	// https://www.youtube.com/watch?v=jfYWwQrtzzY
	mounted() {
		this.minutePosition = this.getCurrentTime().minutePosition;

		const allHeaderHours = document.querySelector("#all-header-hours");
		const differenceTime = this.innerEndHour - this.innerStarHour;

		allHeaderHours.addEventListener('wheel', (event) => {
			event.preventDefault();

			if (event.deltaY > 0 && this.innerEndHour < 24 && ((this.innerEndHour - this.innerStarHour) == differenceTime)) {
				this.innerStarHour = this.innerStarHour + 1;
				this.innerEndHour = this.innerEndHour + 1;
			} else if (event.deltaY < 0 && this.innerStarHour > 0 && ((this.innerEndHour - this.innerStarHour) == differenceTime)) {
				this.innerStarHour = this.innerStarHour - 1;
				this.innerEndHour = this.innerEndHour - 1;
			}
		}, {passive: false});
	},

	props: {
		date: {
			type: Date,
			required: false,
			default: () => new Date(),
		},
		startDay: {
			type: Number,
			required: false,
			default: 1, // 1 - monday
		},
		startHour: {
			type: Number,
			required: false,
			default: 0,
		},
		endHour: {
			type: Number,
			required: false,
			default: 24,
		},
		hideLabelDay: {
			type: Boolean,
			required: false,
			default: false,
		},
		weekEnd: {
			type: Boolean,
			required: false,
			default: true,
		},
		events: {
			type: Array,
			required: false,
			default: () => [],
		},
		useBackgroundImages: {
			type: Boolean,
			required: false,
			default: false,
		},
		defaultColor: {
			type: String,
			required: false,
		},
		allowToDeleteClasses: {
			type: Boolean,
			required: false,
			default: false
		},
		allowMoveSessions: {
			type: Boolean,
			required: false,
			default: false
		},
        showCurrentTime: {
			type: Boolean,
			required: false,
			default: false,
		},
		additionalTeachers: {
			type: Boolean,
			required: false,
			default: false,
		},
	},

	computed: {
		customShadow() {
			let customBoxShadow = this.defaultColor.match(/\d+/g);
			return `rgba(${customBoxShadow[0]}, ${customBoxShadow[1]}, ${customBoxShadow[2]}, 0.5)`;
		},

		hourEvents() {
			let retval = new Array();
			let retval2 = new Array();
			let flagDayTime = false;

			this.events.forEach(event => {
				let dateStartObj = new Date(event.start);
				let dateEndObj = new Date(event.end);

				/* let dateTemp = event.start.split(' ')[0].split('-'); */
				if (dateStartObj.getDate() == dateEndObj.getDate()) {
					let key = event.weekday;
					let lapso = this.getLapso(dateStartObj.getHours(), dateEndObj.getHours());
					let height = this.dayCellSize * lapso - this.getTopMinutes(dateStartObj.getMinutes()) + this.getTopMinutes(dateEndObj.getMinutes());
					let evento = {
						eventName: event.title,
						eventNameSecondary: event.titleSecondary,
						eventImage: event.image,
						dateStart: dateStartObj,
						dateEnd: dateEndObj,
						key: key,
						startHour: dateStartObj.getHours(),
						endHour: dateEndObj.getHours(),
						lapso: lapso,
						startMinutes: dateStartObj.getMinutes(),
						endMinutes: dateEndObj.getMinutes(),
						styles: {
							top: this.getTopMinutes(dateStartObj.getMinutes()) + 'px',
							height: height + 'px',
							backgroundColor: event.defaultBackgroundColor ? event.defaultBackgroundColor : this.defaultColor,
							backgroundImage: "url(" + event.defaultBackgroundImage + ")",
							verticalAlign: 'super',
						},
						students: event.students,
						classroom: event.classroom,
						dayTimeRepetion: 0,
						group: event.group ? event.group : null,
						period: event.period ? event.period : null,
						person: event.person ? event.person : null,
						personType: event.personType ? event.personType : null,
						year: event.year || null,
						sessionId: event.id,
						additional: event.additional,
						isDirector: event.group.section != null ? (event.group.section.teacher && event.group.section.teacher == event.group.teacher.id ? true : false) : false,
						isOptional: event.group.section == null ? true : false,
					};

					var horaInicial = dateStartObj.getHours() + '-' + dateStartObj.getMinutes();
					var key2 = horaInicial;
					var keyMerge = key + ':' + key2;

					if (typeof retval[key] == 'undefined') {
						retval[key] = [];
					}
					if (typeof retval2[keyMerge] == 'undefined') {
						retval2[keyMerge] = [];
					} else {
						flagDayTime = true;
					}

					if (flagDayTime) {
						evento.dayTimeRepetion = retval2[keyMerge].length;
					}

					flagDayTime = false;

					let targetEvent = Object.assign({}, evento);
					retval2[keyMerge].push(targetEvent);

					if (event.additional) {
						if (this.additionalTeachers) {
							retval[key].push(targetEvent);
						}
					} else {
						retval[key].push(targetEvent);
					}

					this.sizeEvent = retval2;
				} /* else {
					let key = dateTemp[2] + '-' + dateTemp[1] + '-' + dateTemp[0];
					let lapsoStart = this.getLapso(dateStartObj.getHours(), 24);
					let restarHeight = 0;

					if (dateEndObj.getMinutes() == 0) {
						restarHeight = dateEndObj.getMinutes() + 20;
					} else {
						restarHeight = 30;
					}

					let evento = {
						eventName: event.title,
						eventNameSecondary: event.titleSecondary,
						eventImage: event.image,
						dateStart: dateStartObj,
						dateEnd: dateEndObj,
						key: key,
						restarHeight: restarHeight,
						startHour: dateStartObj.getHours(),
						endHour: 24,
						startMinutes: dateStartObj.getMinutes(),
						endMinutes: dateEndObj.getMinutes(),
						styles: {
							top: this.getTopMinutes(dateStartObj.getMinutes()) + 'px',
							height: this.dayCellSize * lapsoStart - restarHeight + 'px',
							background: event.defaultBackgroundColor ? event.defaultBackgroundColor : this.defaultColor,
							backgroundImage: "url(" + event.defaultBackgroundImage + ")",
							verticalAlign: 'super',
						},
						students: event.students,
						classroom: event.classroom,
						dayTimeRepetion: 0,
						group: event.group ? event.group : null,
						period: event.period ? event.period : null,
						person: event.person ? event.person : null,
						personType: event.personType ? event.personType : null,
					};

					horaInicial = dateStartObj.getHours() + '-' + dateStartObj.getMinutes();
					key2 = horaInicial;
					keyMerge = key + '-' + key2;

					if (typeof retval[key] == 'undefined') {
						retval[key] = [];
					}

					if (typeof retval2[keyMerge] == 'undefined') {
						retval2[keyMerge] = [];
					} else {
						flagDayTime = true;
					}

					if (flagDayTime) {
						evento.dayTimeRepetion = retval2[keyMerge].length;
					}

					flagDayTime = false;

					let targetEvent = Object.assign({}, evento);
					retval2[keyMerge].push(targetEvent);
					retval[key].push(targetEvent);
					
					this.sizeEvent = retval2;
				} */
			});

			return retval;
		},

		daysOfWeek() {
			let retval = [];
			let startDay = new Date(this.date);
			let offset = startDay.getDay() - this.startDay;

			if (offset < 0) {
				offset = 7 + offset;
			}
			startDay.setDate(startDay.getDate() - offset);

			let curDay = startDay;

			for (let dayN = 0; dayN < 7; dayN++) {
				let day = {
					dayNumber: curDay.getDay(),
					dayName: this.getDayName(curDay.getDay()),
					date: new Date(curDay),
					timestamp: parseInt(curDay.getTime() / 1000),
					key: this.getKey(curDay),
					classnames: [
						{
							today: this.isToday(curDay),
							'other-month': curDay.getMonth() != this.date.getMonth(),
							'first-of-month': curDay.getDate() == 1,
						},
					],
					today: this.isToday(curDay),
				};

				if (this.weekEnd == false && (curDay.getDay() == 0 || curDay.getDay() == 6)) {
					continue;
				}
				retval.push(day);
				curDay.setDate(curDay.getDate() + 1);
			}

			return retval;
		},

		hoursCalendar() {
			let count = this.innerStarHour;
			let retval = [];

			for (let i = this.innerStarHour; i <= this.innerEndHour; i++) {
				let hours = [];

				if (count == 13) {
					count = 1;
				}

				hours = {
					key: i,
					hour: count,
					time: i < 13 ? (i == 12 ? 'PM' : 'AM') : 'PM',
				};

				count = count + 1;
				retval.push(hours);
			}

			return retval;
		},
	},

	methods: {
		onDropZone(e) {
			if (this.allowMoveSessions) {
				const target = e.target;
				target.classList.add("drop-zone");
			}
		},

		offDropZone(e) {
			if (this.allowMoveSessions) {
				const target = e.target;
				target.classList.remove("drop-zone");
			}
		},

		markCurrentTime(day, hour) {
			if (this.showCurrentTime && day == this.getCurrentTime().day && hour == this.getCurrentTime().hour) {
				return true;
			}
			return false;
		},

		getCurrentTime() {
			let current = new Date();
			return {
				"day": current.getDay(),
				"hour": current.getHours(),
				"minute": current.getMinutes(),
				"second": current.getSeconds(),
				"month": current.getMonth() + 1,
				"year": current.getFullYear(),
				"complete": current.getDay() + "-" + (current.getMonth() + 1) + "-" + current.getFullYear(),
				"timestamp": parseInt(current.getTime() / 1000),
				"minutePosition": (this.dayCellSize * current.getMinutes()) / 59,
			};
		},

		onDragStart(e, session) {
			if (this.allowMoveSessions) {
				session.initialStartClass = session.startHour.toString().padStart(2, "0") + ":" + session.startMinutes.toString().padStart(2, "0") + ":00";
				session.initialEndClass = session.endHour.toString().padStart(2, "0") + ":" + session.endMinutes.toString().padStart(2, "0") + ":00";
				
				e.dataTransfer.dropEffect = 'move';
				e.dataTransfer.effectAllowed = 'move';
				e.dataTransfer.setData('sessionClass', JSON.stringify(session));

				setTimeout(() => {
					this.blockEvents(document.querySelectorAll("#event-total"));
				}, 1);
			}
		},

		onDrop(e, day, hour) {
			e.preventDefault();

			if (this.allowMoveSessions) {
				const target = e.target;
				const source = e.srcElement;
				if (e.dataTransfer.getData('sessionClass')) {
					e.dataTransfer.dropEffect = 'move';
		
					const newHour = this.hoursCalendar[hour].key.toString().padStart(2, "0");
					const hourTime = this.hoursCalendar[hour].time;
					const dayName = day.split('-')[0];
					const sessionData = e.dataTransfer.getData('sessionClass');

					target.classList.remove("drop-zone");
					source.classList.remove("drop-zone");
					this.$emit('new-class-event', {day: parseInt(dayName), dayName: this.getDayName(dayName), hour: newHour, time: hourTime, data: sessionData});
				}
			}
		},

		onDragEnd(e) {
			if (this.allowMoveSessions) {
				this.unblockEvents(document.querySelectorAll("#event-total"));
			}
		},

		blockEvents(element) {
			let index = 0, length = element.length;
			for ( ; index < length; index++) {
				element[index].style.opacity = 0.35;
				/* element[index].style.transition = "opacity 0.5s linear 0s";
				element[index].style.zIndex = 1; */
			}
		},

		unblockEvents(element) {
			let index = 0, length = element.length;
			for ( ; index < length; index++) {
				element[index].style.opacity = 1;
				/* element[index].style.transition = "opacity 0.5s linear 0s";
				element[index].style.zIndex = 2; */
			}
		},

		getLapso(start, end) {
			let count = 0;
			let endLimit = 0;
			let countFinal = 0;

			if (end < start) {
				endLimit = 24;
			} else {
				endLimit = end;
			}
			for (let i = start; i < endLimit; i++) {
				count++;
			}
			if (end < start) {
				countFinal = count + end;
			} else {
				countFinal = count;
			}
			return countFinal;
		},

		getTopMinutes(minutes) {
			let valor = 0;
			valor = this.dayCellSize / 59;
			return Math.round(valor * minutes);
		},

		clickDay(day) {
			const weekDay = new Date(day.date).getDay();
			const retvalTemp = [];

			this.events.forEach((event) => {
				if (parseInt(event.weekday) == weekDay && ((this.additionalTeachers == false && event.additional == false) || (this.additionalTeachers == true))) {
					retvalTemp.push(event);
				}
			});
			this.$emit('click-day', { day: day, events: retvalTemp });
		},

		getKey(dateKey) {
			return (dateKey.getDay() + '-' + (dateKey.getMonth() + 1) + '-' + dateKey.getFullYear());
		},

		isToday(date) {
			let today = new Date();

			return (
				date.getFullYear() === today.getFullYear() &&
				date.getMonth() === today.getMonth() &&
				date.getDate() === today.getDate()
			);
		},

		getDayName(day) {
			let days = new Array();
			days = {
				"1": "Monday",
				"2": "Tuesday",
				"3": "Wednesday",
				"4": "Thursday",
				"5": "Friday",
				"6": "Saturday",
				"0": "Sunday"
			};
			return days[day];
		},
	},
};
</script>

<style lang="scss" scoped>
.ui-calendar-academic-schedule-week {
	--cell-size: 46px;
	--custom-text: "sesión aquí";
	--white: #fff;
	--cell-session-color: rgb(26, 115, 232);

	background: var(--white);
	margin: 10px 0px;
	-webkit-font-smoothing: subpixel-antialiased;

	.row {
		display: flex;
		width: 100%;
		height: 100%;
		.ui-calendar-day {
			position: relative;
		}

		.header-hours-vacio {
			min-width: 5%;
			height: 10px !important;
		}

		.header-hours {
			min-width: 5%;
			text-align: center;
			font-size: 11px;
			border-top: 1px solid #dadce0;
			border-top: none !important;
			-webkit-touch-callout: none; /* iOS Safari */
			-webkit-user-select: none; /* Safari */
			-khtml-user-select: none; /* Konqueror HTML */
			-moz-user-select: none; /* Old versions of Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
			user-select: none; /* Non-prefixed version, currently */

			.label-hours {
				position: relative;
				display: block;
				height: var(--cell-size);
				font-weight: 500;
				span {
					position: absolute;
					width: 98%;
					right: 5px;
					top: -10px;
					background: var(--white);
					-webkit-touch-callout: none; /* iOS Safari */
					-webkit-user-select: none; /* Safari */
					-khtml-user-select: none; /* Konqueror HTML */
					-moz-user-select: none; /* Old versions of Firefox */
					-ms-user-select: none; /* Internet Explorer/Edge */
					user-select: none; /* Non-prefixed version, currently */
				}
			}
		}

		.header-hours.gmt {
			border: none;
			font-weight: 500;
		}

		.week {
			width: 13.571%;
			font-weight: 500;
			text-align: center;

			.ui-calendar-day-header {
				text-align: center;
				cursor: pointer;
				height: var(--cell-size);

				.label-weekday {
					font-size: 14px;
				}

				.label-month {
					display: none;
				}

				.label-day {
					display: inline-block;
					width: var(--cell-size);
					height: var(--cell-size);
					font-size: 18px;
					line-height: 40px;
					font-weight: bold;
					text-align: center;
					border-radius: 100%;
					margin-right: 0px;
					/* transition: .8s all ease; */

					&:hover {
						background: #e0e0e0;
					}
				}
			}

			&.today {
				.label-day {
					width: 20px;
					height: 20px;
					text-align: center;
					line-height: 19px;
					border-radius: 100%;
					background-color: var(--white);
					color: var(--cell-session-color);
					font-size: 13px;
					position: absolute;
					top: -4px;
					right: -5px;
					z-index: 2;
					box-shadow: 0 10px 25px rgba(26, 115, 232, .50);
				}

				.label-day-header {
					background-color: var(--cell-session-color);
					border-radius: 100%;
					box-shadow: 0 10px 25px rgba(26, 115, 232, .50);
					color: var(--white);
					display: inline-block;
					font-weight: bold;
					height: var(--cell-size);
					padding-top: 10px !important;
					position: relative;
					text-align: center;
					top: -10px;
					width: var(--cell-size);
				}
			}

			&.first-of-month {
				.label-month {
					display: inline;
				}
			}

			&.other-month {
				background-color: transparent;

				.label-month,
				.label-day {
					opacity: 0.4;
				}
			}
			.event-total {
				border: 1px solid #f2f2f2;
				height: var(--cell-size);
				position: relative;
			}
			.drop-zone {
				min-height: var(--cell-size);
				opacity: 0.5;
				background: white;
				/* border: 1px dashed #0083df; */
			}
			.drop-zone::after {
				font-size: 14px;
				color: #0275c7;
				/* content: var(--custom-text); */
			}
		}
	}
	.row.days {
		padding-top: 10px !important;
	}
	.slide-enter-active, .slide-leave-active {
		transition: 500ms ease;
	}
	.slide-enter, .slide-leave-to {
		transition: 500ms ease;
	}
	.line-marker {
        background: #eb3929;
        position: absolute;
        height: 2px;
        width: 100%;
        pointer-events: none;
        z-index: 999;
		transition: 50ms all ease;

        .point-marker {
            height: 12px;
            width: 12px;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            background: #eb3929;
            position: absolute;
            margin-left: -5.5px;
            margin-top: -5px;
            content: "";
            left: 0;
			transition: 50ms all ease;
        }
    }
}
</style>