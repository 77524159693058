var sectionCache = {};
var courseCache = {};
export default httpClient => ({
  getCourseSections(yearId) {
    if (typeof sectionCache[yearId] == 'undefined') {
      sectionCache[yearId] = httpClient.get("/v3/course/sections", {
        year: yearId
      });
    }
    return sectionCache[yearId];
  },
  getCourseSectionsSchedule(yearId, personId = null) {
    return httpClient.get("/v3/course/section/schedule", {
      year: yearId,
      person: personId
    });
  },
  getCourses(yearId, data = null) {
    if (typeof courseCache[yearId] == 'undefined') {
      courseCache[yearId] = httpClient.get("/v3/courses", {
        year: yearId,
        extra: data
      });
    }
    return courseCache[yearId];
  }
});