<template>
	<div>
		<h2>{{ app.data.title }}</h2>
		<small>Login: {{ app.user.firstName }} {{ app.user.lastName }}</small>

		<!-- console.time('time-loop');
		console.timeEnd('time-loop'); -->

		<!-- 
		<ecommerce-payment-button
			:payment="payment"
			@send="triggerOk"
			@error="triggerError"
		>
			<template #success>
				<h2>Pago completado con éxito</h2>
			</template>

			<template #error="{reset, response}">
				<h2>Ha ocurrido un error</h2>
				<p>{{ response.msg || reset }}</p>

				<button type="button" @click="goBack()">Ir atras</button>
			</template>
		</ecommerce-payment-button> 
		-->
		
		<!--		
		<phi-store-header person-id="1024"></phi-store-header>
		<phi-store-recharge person-id="1024" :options="[10000, 15000, 20000, 50000]"></phi-store-recharge>
		<phi-store-transaction :person-id="1024" :history="5"></phi-store-transaction>
		<phi-image-tool-change-avatar :user = "app.user"></phi-image-tool-change-avatar>
		-->
		
		<!-- Felipe.vue => AcademicSchedule.vue => Schedule.vue => AcademicScheduleCalendar.vue -->
		<academic-schedule
			:start-hour="6"
			:end-hour="15"
			:week-end="false"
			:hide-label-day="false"
			:show-options="true"
			:background-images="false"
			:show-current-time="true"
			:enable-search="true"
			:search-by-color="true"
			:allow-to-delete-classes="true"
			:allow-move-sessions="true"
			:show-option-additional-classes="true"
			:additional-teachers="true"
			:default-color="'rgb(5, 105, 182)'"
			:optional-subjects="true"
			:allow-to-take-attendance="true"
			@event-info="eventInfo($event)"
		></academic-schedule>
		<!--
		<quiero-mi-factura
			:columns="columns"
			:data="records"
			rfc-emisor="EAM_EAM8206049I6"
			title="Reporte facturación electrónica"
		></quiero-mi-factura>
		-->
		<!-- 
		<dian-log
			:columns="columns"
			:data="records"
			:limit="100"
			title="Reporte facturación electrónica"
		>
		</dian-log> 
		-->
		<!-- 
		<signio-report
			:columns="columns"
			:data="records"
			:limit="100"
			title="Reporte proceso en Linea"
		>
		</signio-report> 
		-->
	</div>
</template>

<script>
/*
import PhiStoreHeader from '@/components/Phi/Store/Header.vue';
import PhiStoreRecharge from '@/components/Phi/Store/Recharge.vue';
import PhiStoreTransaction from '@/components/Phi/Store/Transaction.vue';
*/

import app from '@/store/app.js';
import AcademicSchedule from '@/modules/v3/components/Academic/Schedule/AcademicSchedule.vue';
/* import SignioReport from '@/modules/v3/components/Signio/components/SignioDataTable.vue'; */
/* import PhiImageToolChangeAvatar from '@/modules/profile/components/changeavatar/Changeavatar.vue'; */
/* import QuieroMiFactura from '@/modules/v3/components/V3QuieromifacturaLog/V3QuieromifacturaLog.vue'; */
/* import DianLog from '@/modules/v3/components/V3DianLog/V3DianLog.vue'; */

export default {
	components: {
		AcademicSchedule,
		//SignioReport,
		//PhiImageToolChangeAvatar,
		//QuieroMiFactura,
		//DianLog,
		//EcommercePaymentButton,
	},

	data() {
		return {
			app,
			/* payment: {
				id: 't6dehmtioou',
				timestamp: '1653088655',
				payer: 'system',
				value: '43199.00',
				currency: 'COP',
				charge: {
					currency: 'COP',
					value: 43199,
					text: 'Cobro #23775',
					data: {
						type: 'debit',
						id: '6255',
						debit_prices: [
							{
								type: 'debit_price',
								debit: '6255',
								debit_price: '7207',
								price: '793',
								causable: '1',
								value: '25000.00',
							},
							{
								type: 'debit_price',
								debit: '6255',
								debit_price: '7208',
								price: '793',
								causable: '1',
								value: '15500.00',
							},
							{
								type: 'interests',
								debit: '6255',
								date: 1653088654,
								value: 2699,
								observations:
									' 2,699 de interés por 4 días de mora sobre saldo de 40,500 a una tasa de 50%  mensual (desde May 16 2022 hasta May 20 2022)',
							},
						],
					},
				},
				chargeId: null,
				ackUrl: 'https://felipe.phidias.co/person/pay/debit/ack',
				gateway: 'syb19dz89ht',
				gatewayReference: null,
				clientData: null,
				status: 'pending',
				message: '{"success": false,"detail": {"user": {"id": ["This field may not be null."],"email": ["This field may not be null."],"name": ["This field may not be null."],"last_name": ["This field may not be null."]},"configuration": {"allowed_payment_methods": {"1": ["Rappi is not a valid choice."],"2": ["Card is not a valid choice."],"3": ["Qr is not a valid choice."],"4": ["Colsubsidio is not a valid choice."]}}}}',
				dateResponse: null,
				response: null,
				provider: 'paymentez',
				objGateway: {
					id: 'syb19dz89ht',
					name: 'Paymentez Sandbox (Phidias)',
					provider: 'paymentez',
					settings: {
						url: 'https://noccapi-stg.paymentez.com/',
						apiLogin: 'DV-LICSANMIGUE-CO-SERVER',
						apiKey: 'dxA8gAE2E9TeuB5HC19xyC24eEwIMc',
						redirectUrl:
							'https://felipe.api.phidias.co/1/ecommerce/providers/paymentez/ack',
						allowPaymentMethods: 'BankTransfer, Rappi, Card, Qr, Colsubsidio',
					},
					data: {
						creditMethodId: '8',
						creditLocationId: '1',
						billingEntityId: null,
					},
				},
			}, */
			/*columns: [
				{
					label: 'id',
					name: 'document',
					type: 'diandocumentid',
					sticky: 'sticky',
				},
				{
					label: 'Cliente',
					name: 'person',
					type: 'person',
					props: {
						secondaryField: 'email',
					},
				},
				{
					label: 'Estudiante',
					name: 'student',
					type: 'person',
					props: {
						secondaryField: 'email',
					},
				},
				{
					label: 'Documento',
					name: 'documentSequence',
					type: 'text',
				},
				{
					label: 'Fecha de Envio',
					name: 'sentDate',
					type: 'date',
				},
				{
					label: 'Total',
					name: 'value',
					type: 'currency',
					align: 'right',
					props: {
						currency: 'MXN',
					},
					options: {
						totalize: true,
					},
				},
				{
					label: 'Estado',
					name: 'status',
					type: 'quieromifacturaestatus',
					align: 'center',
				},
				{
					label: 'RFC receptor',
					name: 'rfcReceptor',
					type: 'text',
				},
				{
					label: 'Fecha timbrado',
					name: 'fechaTimbrado',
					type: 'text',
				},
				{
					label: 'Folio fiscal',
					name: 'uuid',
					type: 'text',
				},
				{
					label: 'Observaciones',
					name: 'observations',
					type: 'text',
				},
			],
			records: {
				entity: 'NULL',
				documentType: "'INVOICE'",
				status: [201,200,500],
				start_date: '1641016800',
				end_date: '1642399199',
				search: null,
			},*/
			/* columns: [
				{
					label: 'id',
					name: 'document',
					type: 'diandocumentid',
					sticky: 'sticky',
				},
				{
					label: 'Cliente',
					name: 'person',
					type: 'person',
					props: {
						secondaryField: 'email',
					},
				},
				{
					label: 'Documento',
					name: 'dian',
					type: 'diansequence',
				},
				{
					label: 'Total bruto',
					name: 'values.totalBruto',
					type: 'currency',
					align: 'right',
					props: {
						currency: 'COP',
					},
					options: {
						totalize: true,
					},
				},
				{
					label: 'Descuentos (-)',
					name: 'values.totalDescuentos',
					type: 'currency',
					align: 'right',
					props: {
						currency: 'COP',
					},
					options: {
						totalize: true,
					},
				},
				{
					label: 'Anticipos (-)',
					name: 'values.totalAnticipos',
					type: 'currency',
					align: 'right',
					props: {
						currency: 'COP',
					},
					options: {
						totalize: true,
					},
				},
				{
					label: 'Total a pagar',
					name: 'values.totalAPagar',
					type: 'currency',
					align: 'right',
					props: {
						currency: 'COP',
					},
					options: {
						totalize: true,
					},
				},
				{
					label: 'Fecha del Documento',
					name: 'documentDate',
					type: 'date',
				},
				{
					label: 'Fecha de Envio',
					name: 'sentDate',
					type: 'date',
				},
				{
					label: 'Respuesta DIAN',
					name: 'validation',
					type: 'dianvalidation',
				},
				{
					label: 'Estado',
					name: 'clientStatus',
					type: 'text',
				},
				{
					label: 'Obs. Cliente',
					name: 'clientObservations',
					type: 'text',
				},
				{
					label: 'Enlaces',
					name: 'links',
					type: 'dianlinks',
				},
			],
			records: {
				entity: null,
				only_valid_documents: 1,
				documentType: "'INVOICE','CREDITNOTE','DEBITNOTE'",
				status: [100,200,500],
				start_date: '1609477200',
				end_date: '1642352125',
				search: null,
			}, 
			columns: [
				{
					label: 'Responsable',
					name: 'responsible',
					type: 'person',
					props: {
						secondaryField: 'email',
					},
				},
				{
					label: 'Estudiante',
					name: 'linkedTransaction',
					type: 'signiotarget',
				},
				{
					label: 'Transacción',
					name: 'transactionId',
					type: 'text',
				},
				{
					label: 'Fecha de firma',
					name: 'dateSignedDocument',
					type: 'date',
				},
				{
					label: 'Documento firmado',
					name: 'data',
					type: 'signiodocument',
				},
				{
					label: 'Archivo',
					name: 'linkDocument',
					type: 'signiolink',
				},
			],
			records: {
				start_date: '1651381200',
				end_date: '1654059599',
				processId: 18,
			},*/
		};
	},

	methods: {
		eventInfo(event) {
			console.log('Desde el componente principal: ', event);
		},
		triggerOk($event) {
			console.log('Transacción éxitosa: ', $event);
		},
		triggerError($event) {
			console.log('Error en la transacción: ', $event);
		},
	},
};
</script>