<template>
    <div class="ui-calendar-event evento"
		:style="[
			sizeDayEvent(day, event) > 1 ? { width: Math.round(98 / sizeDayEvent(day, event)) + '%', } : { width: '98%', left: '0' }, 
			event.styles,
			{ boxShadow: '0 10px 25px ' + customShadow },
			{ left: event.dayTimeRepetion * Math.round(98 / sizeDayEvent(day, event)) + '%', },
			backgroundimage(event)]"
		@click="clickEvent(event)"
		:draggable="allowMoveSessions && !event.additional"
		@dragover.stop
		@dragend="$emit('drag-end', $event)"
		@dragstart="$emit('drag-start', $event)"
	>
		<span class="tint-event" :style="[ tintColor(event)]"></span>
		<p class="event-detail">
			<span class="label-event" :title="event.isDirector ? $t('AcademicSchedule.class.director') : $t('AcademicSchedule.class.teacher')">
				<!-- director de grupo -->
				<img loading="lazy" v-if="event.isDirector" :src="principal" width="14" height="14">
				<!-- Nombre evento -->
				<span class="event-name">{{ event.eventName }}</span>
				<!-- icono oṕcional -->
				<img loading="lazy" v-if="event.isOptional" width="14" height="14" :src="optionalClasses" :title="$t('AcademicSchedule.optional.classes')">
				<!-- eliminar clases -->
				<img loading="lazy" v-if="allowToDeleteClasses && !event.additional" :src="deleteClasses" width="14" height="14" class="delete-event" :title="$t('AcademicSchedule.delete.classes')" @click="deleteEvent($event, event)">
				<!-- profesor suplente -->
				<img loading="lazy" v-else-if="event.additional" :src="additionalClasses" width="14" height="14" class="additional-events" :title="$t('AcademicSchedule.addtional.classes')">
			</span>
			{{ $date(event.dateStart, 'time') }} - {{ $date(event.dateEnd, 'time') }} <span v-if="event.classroom"> - {{ $t('AcademicSchedule.classroom') }}: {{ event.classroom }} </span> <br>
			{{ event.eventNameSecondary }}
		</p>
	</div>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import principal from './img/principal.svg';
import deleteClasses from './img/delete-classes.svg';
import additionalClasses from './img/additional.svg';
import optionalClasses from './img/optional.svg';

export default {
    name: 'ui-calendar-event',
	mixins: [useI18n],
    data() {
        return {
			dayCellSize: 46,
			principal,
			deleteClasses,
			additionalClasses,
			optionalClasses,
        }
    },

    props: {
        day: {
            type: Number,
            required: true,
        },
		hour: {
			type: Number,
		},
		event: {
			type: Object,
		},
		allowToDeleteClasses: {
			type: Boolean,
			required: false,
			default: false
		},
		allowMoveSessions: {
			type: Boolean,
			required: false,
			default: false
		},
		useBackgroundImages: {
			type: Boolean,
			required: false,
			default: false,
		},
		defaultColor: {
			type: String,
			required: false,
		},
		sizeEvent: {
			type: Array,
		},
		additionalTeachers: {
			type: Boolean,
			required: false,
			default: false,
		},
    },

	computed: {
		customShadow() {
			let customBoxShadow = this.defaultColor.match(/\d+/g);
			return `rgba(${customBoxShadow[0]}, ${customBoxShadow[1]}, ${customBoxShadow[2]}, 0.5)`;
		}
	},

    methods: {
		customShadowAdditional(color) {
			let customBoxShadow = color.match(/\d+/g);
			return `rgba(${customBoxShadow[0]}, ${customBoxShadow[1]}, ${customBoxShadow[2]}, .65)`;
		},

		clickEvent(event) {
			this.$emit('click-event', event);
		},

        sizeDayEvent(day, event) {
			var key = day + ':' + event.startHour + '-' + event.startMinutes;
			return this.sizeEvent[key].length;
		},

		getHeightMinutes(minutes) {
			let resultado = 0;
			if (minutes > 0) {
				let valor = this.dayCellSize / 59;
				let valorMinutos = 59 - minutes;
				resultado = Math.round(valor * valorMinutos);
			} else {
				resultado = this.dayCellSize;
			}
			return resultado;
		},

		tintColor: function(json) {
			if (json.group.data !== null) {
				let data = JSON.parse(json.group.data);
				if (typeof data.card !== "undefined" && this.useBackgroundImages) {
					if (typeof data.card.color !== "undefined") {
						return {
							"background-color": data.card.color ? data.card.color : this.defaultColor,
							"opacity": data.card.tintOpacity,
						};
					}
				}
			}
		},

        backgroundimage: function(json) {
			if (json.group.data !== null) {
				let data = JSON.parse(json.group.data);
				if (typeof data.card !== "undefined" && this.useBackgroundImages) {
					if (typeof data.card.cover !== "undefined") {
						return {
							"background-color": data.card.color ? data.card.color : this.defaultColor,
							"background-image": "url(" + data.card.cover + ")", 
							"background-repeat": "no-repeat",
							"background-size": "cover", 
							"backdrop-filter": 'blur(10px)',
							"background-position-y": data.card.coverOffset + "%",
						};
					} else if (typeof data.card.color !== "undefined") {
						return {
							"background-color": data.card.color
						};
					} else {
						return {
							"background-color": json.additional ? this.customShadowAdditional(this.defaultColor) : this.defaultColor,
							"backdrop-filter": 'blur(10px)',
						};
					}
				} else {
					return {
						"background-color": json.additional ? this.customShadowAdditional(this.defaultColor) : this.defaultColor,
						"backdrop-filter": 'blur(10px)',
					};
				}
			}
			return {
				"background-color": json.additional ? this.customShadowAdditional(this.defaultColor) : this.defaultColor,
				"backdrop-filter": 'blur(10px)',
			};
		},

        deleteEvent(evt, classEvent) {
			evt.stopPropagation();

			let evtObj = {};

			evtObj.group = classEvent.group.id;
			evtObj.period = classEvent.period;
			evtObj.weekday = classEvent.key;
			evtObj.startHour = classEvent.startHour.toString().padStart(2, "0") + ":" + classEvent.startMinutes.toString().padStart(2, "0") + ":00";
			evtObj.endHour = classEvent.endHour.toString().padStart(2, "0") + ":" + classEvent.endMinutes.toString().padStart(2, "0") + ":00";
			evtObj.groupName = classEvent.group.name;
			evtObj.eventName = classEvent.eventName;
			evtObj.classroom = classEvent.classroom;
			evtObj.weekDayName = this.getDayName(classEvent.key);
			evtObj.sessionId = classEvent.sessionId;

			this.$emit('delete-event', evtObj);
		},
        
        getDayName(day) {
			let days = new Array();
			days = {
				"1": "Monday",
				"2": "Tuesday",
				"3": "Wednesday",
				"4": "Thursday",
				"5": "Friday",
				"6": "Saturday",
				"0": "Sunday"
			};
			return days[day];
		},
    },

    i18n: {
		es: {
			'AcademicSchedule.delete.classes': 'eliminar sesión',
			'AcademicSchedule.addtional.classes': 'profesor adicional',
			'AcademicSchedule.class.director': 'director de grupo',
			'AcademicSchedule.class.teacher': 'profesor',
			'AcademicSchedule.classroom': 'Aula',
			'AcademicSchedule.optional.classes': 'clase opcional',
		},
		en: {
			'AcademicSchedule.delete.classes': 'delete session',
			'AcademicSchedule.addtional.classes': 'additional teacher',
			'AcademicSchedule.class.director': 'group director',
			'AcademicSchedule.class.teacher': 'teacher',
			'AcademicSchedule.classroom': 'Classroom',
			'AcademicSchedule.optional.classes': 'optional classes',
		},
		de: {
			'AcademicSchedule.delete.classes': 'Sitzung löschen',
			'AcademicSchedule.addtional.classes': 'zusätzlicher Lehrer',
			'AcademicSchedule.class.director': 'Gruppenleiter',
			'AcademicSchedule.class.teacher': 'Aushilfslehrer',
			'AcademicSchedule.classroom': 'Raum',
			'AcademicSchedule.optional.classes': 'Wahlpflichtfächer',
		},
	},
}
</script>

<style lang="scss" scoped>
.ui-calendar-event {
	--white: #fff;
	--text-move: "moviendo clase";
	--box-shadow: rgba(26, 115, 232, 0.5);

	.ui-calendar-day-contents {
		opacity: 1;
	}

    &.evento {
        border: 1px solid rgba(242, 242, 242, 0.8);
        background: transparent;
        border-radius: 5px;
		box-shadow: 0 10px 25px var(--box-shadow);
        cursor: pointer;
        padding: 0px 5px 0px 5px;
        position: absolute;
        left: 0;
        text-align: left;
        top: 0;
        width: 98%;
        z-index: 1;
		overflow: hidden;

		.event-detail {
			-webkit-touch-callout: none; /* iOS Safari */
			-webkit-user-select: none; /* Safari */
			-khtml-user-select: none; /* Konqueror HTML */
			-moz-user-select: none; /* Old versions of Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
			user-select: none; /* Non-prefixed version, currently */
		}
		
		.event-detail {
			position: relative;
			color: var(--white);
			white-space: nowrap;
			text-overflow: ellipsis;
			font-size: 11px;
			margin: 1px;
			height: calc(100% - 5px);
			overflow: hidden;
			
			.label-event {
				display: flex;
				font-size: 14px;
				font-weight: bold;
				text-overflow: ellipsis;
				overflow: hidden;
				gap: 5px;
				align-items: center;
			}

			.label-event-secondary {
				display: block;
				font-weight: normal;
			}

			.label-event-terciary {
				display: block;
				font-weight: normal;
			}
			
			.event-name {
				text-overflow: ellipsis;
				overflow: hidden;
				flex-grow: 1;
			}
		}

    }
    .delete-event, .additional-events {
        font-weight: bold;
        margin: auto;
        text-align: center;
	}
    .tint-event {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}
</style>