<template>
    <ui-select
        class="v3-course-section-person-picker"
        :options="options"
        v-model="innerValue"
        @input="$emit('input', innerValue)"
        :placeholder="placeholder"
        :multiple="multiple"
    ></ui-select>
</template>

<script>
import useApi from '@/modules/api/mixins/useApi'
import apiCourses from '@/modules/v3/api/courses'

import {UiSelect} from '@/modules/ui/components'

export default {
    name: "v3-course-section-person-picker",
    mixins: [useApi],
    api: apiCourses,

    components: {
        UiSelect,
    },

    props: {
        value: {},

        placeholder: {
            type: String,
            required: false,
            default: ''
        },

        yearId: {
            type: String,
            required: true
        },

        periodId: {
            type: String,
            required: false,
        },

        multiple: {
            type: Boolean,
            default: false
        },

        personId: {
            type: String,
            required: false,
            default: null
        },
    },

    data() {
        return {
            innerValue: null,
            isLoading: false,
            sections: []
        }
    },

    computed: {
        options() {
            return this.sections.map(section => {
                return {
                    value: section.id,
                    text: section.name,
                    secondary: section.course.name,
                    group: section.course.category.name
                };
            });
        },

        categories() {
            let categories = {};
            for (let i = 0; i < this.sections.length; i++) {

                let category = JSON.parse( JSON.stringify(this.sections[i].course.category) );
                if ( typeof categories[category.id] == "undefined") {
                    categories[category.id] = category;
                    categories[category.id].courses = {};
                }

                let course = JSON.parse( JSON.stringify(this.sections[i].course) );
                course.category = undefined;
                if (typeof categories[category.id].courses[course.id] == "undefined") {
                    categories[category.id].courses[course.id] = course;
                    categories[category.id].courses[course.id].sections = [];
                }

                let section = JSON.parse( JSON.stringify(this.sections[i]) );
                section.course = undefined;
                categories[category.id].courses[course.id].sections.push(section);
            }

            return Object.values(categories).map(cat => {
                cat.courses = Object.values(cat.courses);
                return cat;
            });
        }
    },

    methods: {
        async fetchSections() {
            this.isLoading = true;
            this.sections = await this.$api.getCourseSectionsSchedule(this.yearId, this.periodId, this.personId);
            this.isLoading = false;
            this.$emit('fetched', this.sections);
        }
    },

    watch: {
        value: {
            immediate: true,
            handler(newValue) {
                this.innerValue = newValue;
            }
        },

        yearId: {
            immediate: true,
            async handler() {
                await this.fetchSections();
            }
        }
    }
}
</script>