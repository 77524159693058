<template>
	<div class="academic-schedule ui-card card">
		<!-- BEGIN: Tabs que displiegan menu de opciones horizontal: Estudiante (tab = 1), Profesor (tab = 2), Grupo (tab = 3), Curso (tab = 4) -->
		<div class="ui-tabs">
			<ui-tabs
				v-model="selectedTab"
				:allowReSelection="true"
				@input="changeTab"
			>
				<ui-tab
					v-for="option in scheduleOptions"
					:key="option.id"
					:value="option.id"
					:label="$t('AcademicSchedule.label.' + option.option)"
					:icon="option.icon"
				></ui-tab>
			</ui-tabs>
			<div id="refresh" v-if="existsAcademicSchedule > 0">
				<span
					class="mdi mdi-refresh"
					style="cursor: pointer;"
					@click="refresh()"
					:title="$t('AcademicSchedule.message.refresh.data')"
				></span>
			</div>
		</div>
		<!-- END: Tabs -->

		<!-- BEGIN: Ui-item para desplegar información del estudiante cuando se hace una busqueda, o información de un grupo o curso -->
		<div class="v3-person-picker">
			<person-item
				v-if="dataAvailable"
				:person="selPerson"
				:secondary="selPerson.email"
				class="ui-clickable"
				@click="doClickPersonItem"
				formal
				:title="$t('AcademicSchedule.input.search')"
			>
				<ui-icon slot="right" value="g:search" v-if="rol == 0"></ui-icon>
			</person-item>
			<ui-item
				v-if="selectedTab == 3 && selGroup && groupInformation"
				:text="$t('AcademicSchedule.area') + ': ' + groupInformation.course.subject.area.name + ' | ' + $t('AcademicSchedule.group') + ': ' + groupInformation.name"
				:secondary="$t('AcademicSchedule.subject') + ': ' + groupInformation.course.subject.name"
				class="ui-clickable"
				@click="doClickGroupItem"
				:title="$t('AcademicSchedule.input.search')"
			>
				<ui-icon slot="right" value="g:search" v-if="rol == 0"></ui-icon>
			</ui-item>
			<ui-item
				v-if="selectedTab == 4 && selCourseSection && courseSectionInformation"
				:text="$t('AcademicSchedule.course') + ': ' + (courseSectionInformation.course.official_name != '' && courseSectionInformation.course.official_name ? courseSectionInformation.course.official_name + ' ' + courseSectionInformation.name : courseSectionInformation.name)"
				:secondary="$t('AcademicSchedule.course.category') + ': ' + courseSectionInformation.course.category.name"
				:tertiary="$t('AcademicSchedule.course.director') + ': ' + courseSectionInformation.director.firstname + ' ' + courseSectionInformation.director.lastname + ' ' + courseSectionInformation.director.email"
				class="ui-clickable"
				@click="doClickCourseSectionItem"
				:title="$t('AcademicSchedule.input.search')"
			>
				<ui-icon slot="right" value="g:search" v-if="rol == 0"></ui-icon>
			</ui-item>
			<ui-item
				v-if="selectedTab == 5 && selCourse && courseInformation"
				:text="$t('AcademicSchedule.course') + ': ' + (courseInformation.official_name != '' && courseInformation.official_name != null ? courseInformation.official_name : courseInformation.name)"
				:secondary="courseInformation.category.name"
				class="ui-clickable"
				@click="doClickCourseItem"
				:title="$t('AcademicSchedule.input.search')"
			>
				<ui-icon slot="right" value="g:search" v-if="rol == 0"></ui-icon>
			</ui-item>

			<v3-person-picker
				v-if="!dataAvailable && selectedTab != 3 && selectedTab != 4 && selectedTab != 5"
				:placeholder="$t('AcademicSchedule.input.search')"
				:type="selectedTab"
				@input="inputSearch"
			></v3-person-picker>
		</div>
		<!-- END: Ui-item para desplegar información del estudiante cuando se hace una busqueda, o información de un grupo o curso -->

		<!-- Pickers relacionados a: Año, Periodo, Grupo, Sección y Curso -->
		<div class="v3-academic-year-period-group-picker" v-show="(!selYear && !selPeriod) || showYearPeriodPicker">
			<!-- BEGIN: Pickers -->
			<div class="pickers">
				<v3-academic-year-picker
					v-model="selYear"
					:auto-select="true"
					@input="onYearChange"
					class="ui-native"
					:placeholder="$t('AcademicSchedule.select.academic.year')"
				></v3-academic-year-picker>

				<template v-if="selYear && selYear.id">
					<v3-academic-period-schedule-picker
						v-model="selPeriod"
						:auto-select="false"
						:multiple="false"
						:year-id="selYear.id"
						:query="selPerson"
						@fetched="loadPeriodCategories($event)"
						:placeholder="$t('AcademicSchedule.select.period')"
					></v3-academic-period-schedule-picker>

					<v3-academic-course-group-by-period-picker
						v-if="selPeriod && (rol == 0 || rol == 2) && selectedTab == 3"
						v-model="selGroup"
						:year-id="selYear.id"
						:period-id="selPeriod"
						:query="selPerson"
						@fetched="loadAcademicCourseGroupByPeriod($event)"
						@input="getGroupDetails(selGroup)"
						:placeholder="$t('AcademicSchedule.select.group')"
					></v3-academic-course-group-by-period-picker>

					<v3-course-section-person-picker
						v-if="(rol == 0 || rol == 2) && selectedTab == 4 && selPeriod"
						v-model="selCourseSection"
						:year-id="selYear.id"
						:person-id="selPerson.id"
						:placeholder="$t('AcademicSchedule.select.course.section')"
						@fetched="loadCourseSectionPerson($event)"
						@input="getCourseSectionDetails(selCourseSection)"
					>
					</v3-course-section-person-picker>

					<v3-course-picker
						v-if="(rol == 0 || rol == 2) && selectedTab == 5 && selPeriod"
						v-model="selCourse"
						:year-id="selYear.id"
						:placeholder="$t('AcademicSchedule.select.course')"
						@fetched="loadCourses($event)"
						@input="getCourseDetails(selCourse)"
					>
					</v3-course-picker>

					<span>
						<button
							type="button"
							class="schedule-button ui-button --main"
							style="min-height: 44px !important"
							@click="getSchedule"
							v-if="buttonTrigger && existsAcademicSchedule == 0 && originalAcademicSchedule.length == 0"
						>
							<ui-icon value="mdi:calendar-month"></ui-icon>
							<span style="padding-left: 10px;">{{
								$t('AcademicSchedyle.search.shedule')
							}}</span>
						</button>
					</span>

					<input
						type="search"
						v-model="searchSchedule"
						:placeholder="$t('AcademicSchedule.filter')"
						spellcheck="false"
						class="ui-search"
						v-if="enableSearch && originalAcademicSchedule.length > 0"
						@keyup.esc="searchSchedule = ''"
					/>
				</template>
			</div>
			<!-- END: Pickers -->

			<!-- BEGIN: Opciones de calendario -->
			<div v-if="existsAcademicSchedule > 0" class="options">
				<span v-if="selectedTab == 2 && showOptionAdditionalClasses">
					{{ $t('AcademicSchedule.label.additional.teachers') }}
					<input type="checkbox" name="options" model="internalAdditionalTeachers" :checked="internalAdditionalTeachers" @click="internalAdditionalTeachers = !internalAdditionalTeachers"/>
				</span>
				
				<!-- <span v-if="(selectedTab == 1 || selectedTab == 2 || selectedTab == 3) && buttonTrigger">
					{{ $t('AcademicSchedule.label.optional.subjects') }}
					<input type="checkbox" name="options" model="internalOptionalSubjects" :checked="internalOptionalSubjects" @click="internalOptionalSubjects = !internalOptionalSubjects"/>
				</span>  -->
				
				<span v-if="showOptions">
					{{ $t('AcademicSchedule.label.background.images') }}
					<input type="checkbox" name="options" model="internalBackgroundImages" :checked="internalBackgroundImages" @click="internalBackgroundImages = !internalBackgroundImages"/>
				</span>
				<span v-if="showOptions">
					{{ $t('AcademicSchedule.label.current.time.mark') }}
					<input type="checkbox" name="options" model="internalCurrentTime" :checked="internalCurrentTime" @click="internalCurrentTime = !internalCurrentTime"/>
				</span>
			</div>
			<!-- END: Opciones de calendario -->
		</div>

		<!-- BEGIN: Componente schedule -->
		<div v-if="existsAcademicSchedule > 0" class="ui-card academic-schedule">
			<v3-academic-schedule
				:data="academicSchedule"
				:start-hour="startHour"
				:end-hour="endHour"
				:week-end="weekEnd"
				:hide-label-day="hideLabelDay"
				:use-background-images="internalBackgroundImages"
				:show-current-time="internalCurrentTime"
				:default-color="defaultColor"
				:allow-to-delete-classes="allowToDeleteClasses && rol == 0"
				:allowMoveSessions="allowMoveSessions && rol == 0"
				:additional-teachers="internalAdditionalTeachers"
				:rol="rol"
				:allow-to-take-attendance="allowToTakeAttendance && rol == 2"
				@click-event-info="clickEventInfo({ info: $event })"
				@click-day="clickEventInfo({ day: $event })"
				@click-event="clickEventInfo({ event: $event })"
				@delete-class-room="clickEventDeleteClassroom({ data: $event })"
				@move-class="updateEventSession($event)"
			>
			</v3-academic-schedule>
		</div>
		<!-- END: Componente schedule -->

		<!-- BEGIN: Mensajes en el footer -->
		<div v-if="search" class="empty ui-card">
			<h5 style="color: grey;">
				{{ $t('AcademicSchedule.searching.schedule') }}
			</h5>
		</div>

		<div v-if="trigger && existsAcademicSchedule == 0" class="empty ui-card">
			<h5 style="color: black;">{{ $t('AcademicSchedule.not.schedules') }}</h5>
		</div>

		<div v-if="periodCategories.length == 0" class="empty ui-card">
			<h5 style="color: black;">
				{{ $t('AcademicSchedule.message.no.period.found') }}: {{ selYear ? selYear.name : null }}
			</h5>
		</div>

		<div v-if="(courses.length == 0 && courseSectionPerson.length == 0) && (this.selectedTab == 4 || this.selectedTab == 5)" class="empty ui-card">
			<h5 style="color: black;">
				{{ $t('AcademicSchedule.message.no.courses.found') }}: {{ selYear ? selYear.name : null }}
			</h5>
		</div>
		<!-- END: Mensajes en el footer -->
	</div>
</template>

<script>
import useApi from '@/modules/api/mixins/useApi';
import Api from '@/modules/v3/components/Academic/Schedule/api';
import useI18n from '@/modules/i18n/mixins/useI18n.js';

import UiTab from '@/modules/ui/components/UiTabs/UiTab.vue';
import UiTabs from '@/modules/ui/components/UiTabs/UiTabs.vue';
import UiIcon from '@/modules/ui/components/UiIcon/UiIcon.vue';
import UiItem from '@/modules/ui/components/UiItem/UiItem.vue';
import PersonItem from '@/modules/core/components/Person/ListItem.vue';
import V3PersonPicker from '@/modules/v3/components/V3PersonPicker/V3PersonPicker.vue';
import V3AcademicYearPicker from '@/modules/v3/components/V3YearPicker/V3YearPicker.vue';
import V3AcademicPeriodSchedulePicker from '@/modules/v3/components/V3AcademicPeriodPicker/V3AcademicPeriodSchedulePicker.vue';
import V3AcademicCourseGroupByPeriodPicker from '@/modules/v3/components/V3AcademicCourseGroupPicker/V3AcademicCourseGroupByPeriodPicker.vue';
import V3CourseSectionPersonPicker from '@/modules/v3/components/V3CourseSectionPicker/V3CourseSectionPersonPicker.vue';
import V3CoursePicker from '@/modules/v3/components/V3CoursePicker/V3CoursePicker.vue';

/* Componente Calendario */
import v3AcademicSchedule from '@/modules/v3/components/Academic/Schedule/Schedule.vue';

export default {
	components: {
		UiTab,
		UiTabs,
		UiIcon,
		UiItem,
		PersonItem,
		V3PersonPicker,
		V3AcademicYearPicker,
		V3AcademicPeriodSchedulePicker,
		V3AcademicCourseGroupByPeriodPicker,
		V3CourseSectionPersonPicker,
		V3CoursePicker,
		v3AcademicSchedule,
	},
	$api: Api,
	mixins: [useApi, useI18n],

	data() {
		return {
			selPerson: {},
			selYear: {},
			selPeriod: null,
			selGroup: null,
			selCourseSection: null,
			selCourse: null,
			selRol: this.selectedTab,
			q: {},
			picker: true,
			groupInformation: null,
			courseSectionInformation: null,
			courseInformation: null,
			selectedTab: this.rol ? this.rol : 1,
			showYearPeriodPicker: true,
			academicSchedule: [],
			originalAcademicSchedule: [],
			datos: [],
			trigger: false,
			search: false,
			internalBackgroundImages: this.backgroundImages,
			internalCurrentTime: this.showCurrentTime,
			internalAdditionalTeachers: this.additionalTeachers,
			internalOptionalSubjects: this.optionalSubjects,
			searchSchedule: '',
			flag: false,
			groupTeachers: new Object(),
			periodCategories: [],
			courseSectionPerson: [],
			academicCourseGroupByPeriod: [],
			courses: [],
		};
	},

	props: {
		person: {
			type: [Array, Number],
			required: false,
		},
		rol: {
			type: Number,
			required: false,
			default: 0,
		},
		activeTab: {
			type: Number,
			required: false,
			default: 1,
		},
		startHour: {
			type: Number,
			required: false,
		},
		endHour: {
			type: Number,
			required: false,
		},
		weekEnd: {
			type: Boolean,
			required: false,
		},
		hideLabelDay: {
			type: Boolean,
			required: false,
			default: false,
		},
		backgroundImages: {
			type: Boolean,
			required: false,
			default: false,
		},
		showCurrentTime: {
			type: Boolean,
			required: false,
			default: false,
		},
		showOptions: {
			type: Boolean,
			required: false,
			default: false,
		},
		defaultColor: {
			type: String,
			required: false,
			default: 'rgb(26, 115, 232)',
		},
		enableSearch: {
			type: Boolean,
			required: false,
			default: false,
		},
		searchByColor: {
			type: Boolean,
			required: false,
			default: true,
		},
		allowToDeleteClasses: {
			type: Boolean,
			required: false,
			default: false,
		},
		allowMoveSessions: {
			type: Boolean,
			required: false,
			default: false,
		},
		showOptionAdditionalClasses: {
			type: Boolean,
			required: false,
			default: false,
		},
		additionalTeachers: {
			type: Boolean,
			required: false,
			default: false,
		},
		optionalSubjects: {
			type: Boolean,
			required: false,
			default: false,
		},
		allowToTakeAttendance: {
			type: Boolean,
			required: false,
			default: false,
		}
	},

	mounted() {
		// Parámetro para especificar que solo me debe cargar periodos que tengan asignados horas de sesión
		this.q.module = 'schedulle';
	},

	watch: {
		selectedTab: {
			immediate: true,
			handler: function() {
				if (this.person) {
					this.personDetail(this.person);
				} else {
					this.selPerson = {};
				}
				this.trigger = false;
				this.search = false;
				this.selPeriod = null;
				this.selGroup = null;
				this.selCourseSection = null;
				this.selCourse = null;
				this.academicSchedule = [];
				this.originalAcademicSchedule = [];
			},
		},

		selYear: {
			immediate: true,
			deep: true,
			handler: function() {
				this.selCourse = null;
				this.selGroup = null;
				this.selPeriod = null;
			},
		},

		rol: {
			immediate: true,
			handler: function(val) {
				if (val == 1 || (val == 2 && this.person)) {
					this.personDetail(this.person);
				}
			},
		},

		selPeriod: {
			immediate: true,
			handler: function(val) {
				if (val !== null) {
					this.selGroup = null;
					this.academicSchedule = [];
					this.originalAcademicSchedule = [];
				}
			},
		},

		selGroup: {
			immediate: true,
			handler: function(val) {
				if (val !== null) {
					this.academicSchedule = [];
					this.originalAcademicSchedule = [];
				}
			},
		},

		selCourseSection: {
			immediate: true,
			handler: function(val) {
				if (val !== null) {
					this.academicSchedule = [];
					this.originalAcademicSchedule = [];
				}
			},
		},

		selCourse: {
			immediate: true,
			handler: function(val) {
				if (val !== null) {
					this.trigger = false;
					this.academicSchedule = [];
					this.originalAcademicSchedule = [];
				}
			},
		},

		academicSchedule: {
			handler: function(val) {
				if (val.length > 0) {
					this.$emit('fetched', true);
				}
			},
		},

		backgroundImages: {
			immediate: true,
			handler: function(val) {
				this.internalBackgroundImages = val;
			},
		},

		showCurrentTime: {
			immediate: true,
			handler: function(val) {
				this.internalCurrentTime = val;
			},
		},

		searchSchedule: {
			immediate: true,
			deep: true,
			handler: function(val) {
				val = val.toLowerCase();
				if (val.length >= 3 && this.originalAcademicSchedule.length > 0) {
					if (this.searchByColor) {
						this.academicSchedule.map((element) => {
							let data = element.group.data != null ? JSON.parse(element.group.data) : null;
							let searchItem = element.title.toLowerCase() + ' ' + element.start + ' ' + element.end;
							if (searchItem.includes(val)) {
								element.defaultBackgroundColor = element.additional ? this.customShadowAdditional(this.defaultColor) : (this.backgroundImages && data?.card?.color || this.defaultColor);
								element.defaultBackgroundImage = element.additional ? this.customShadowAdditional(this.defaultColor) : this.backgroundImages && data?.card?.cover || 'none';
							} else {
								element.defaultBackgroundColor = this.customShadow;
								element.defaultBackgroundImage = 'none';
							}
						});
					} else {
						this.academicSchedule = this.academicSchedule.filter((element) => {
							let searchItem = element.title.toLowerCase() + ' ' + element.start + ' ' + element.end;
							return searchItem.includes(val);
						});
					}
					if (this.academicSchedule.length == 0) {
						this.trigger = true;
					}
					this.flag = true;
				} else if (this.flag) {
					if (this.searchByColor) {
						this.academicSchedule.forEach((element) => {
							let data = element.group.data != null ? JSON.parse(element.group.data) : null;
							element.defaultBackgroundColor = element.additional ? this.customShadowAdditional(this.defaultColor) : (this.backgroundImages && data?.card?.color?.substring(0, 7) || this.defaultColor);
							element.defaultBackgroundImage = element.additional ? this.customShadowAdditional(this.defaultColor) : this.backgroundImages && data?.card?.cover || 'none';
						});
					} else {
						this.academicSchedule = this.originalAcademicSchedule;
					}
					this.flag = false;
				}
			},
		},

		internalAdditionalTeachers: {
			immediate: true,
			handler: function(val) {
				this.internalAdditionalTeachers = val;	
			}
		},

		internalOptionalSubjects: {
			immediate: false,
			handler: function(val) {
				this.internalOptionalSubjects = val;
				this.getSchedule();	
			}
		},

		existsAcademicSchedule: {
			immediate: false,
			handler: function(val) {
				if (val == 0) {
					this.trigger = false;
				}
			}
		},
	},

	computed: {
		customShadow: function () {
			let customBoxShadow = this.defaultColor.match(/\d+/g);
			return `rgba(${customBoxShadow[0]}, ${customBoxShadow[1]}, ${customBoxShadow[2]}, 0.5)`;
		},

		dataAvailable: function() {
			return Object.keys(this.selPerson).length > 0;
		},

		buttonTrigger: function() {
			if (
				((this.selectedTab == 1 || this.selectedTab == 2) && this.selYear && this.selPeriod) || 
				(this.selectedTab == 3 && this.selYear && this.selPeriod && this.selGroup) ||
				(this.selectedTab == 4 && this.selYear && this.selCourseSection) ||
				(this.selectedTab == 5 && this.selYear && this.selCourse)
			) {
				return true;
			}
			return false;
		},

		scheduleOptions: function() {
			let retVal;

			if (this.rol == 0) {
				// root - Administrador
				retVal = [
					{ id: 1, option: 'student', icon: 'mdi:account' },
					{ id: 2, option: 'teacher', icon: 'mdi:account-tie' },
					{ id: 3, option: 'group', icon: 'mdi:account-multiple' },
					{ id: 4, option: 'section', icon: 'mdi:account-group' },
					{ id: 5, option: 'course', icon: 'mdi:school' },
				];
			} else if (this.rol == 1 || this.rol == 3) {
				// student - familiar
				retVal = [{ id: 1, option: 'student', icon: 'mdi:account' }];
			} else if (this.rol == 2) {
				// colaborador - teacher
				retVal = [
					{ id: 2, option: 'teacher', icon: 'mdi:account-tie' },
					{ id: 3, option: 'group', icon: 'mdi:account-multiple' },
					{ id: 4, option: 'section', icon: 'mdi:account-group' },
					{ id: 5, option: 'course', icon: 'mdi:school' },
				];
			}
			return retVal;
		},

		existsAcademicSchedule: function() {
			return this.academicSchedule.length;
		},
	},

	methods: {
		loadCourses(courses) {
			this.courses = courses;
		},

		loadAcademicCourseGroupByPeriod(academicCourseGroupByPeriod) {
			this.academicCourseGroupByPeriod = academicCourseGroupByPeriod;
		},

		loadPeriodCategories(periodCategories) {
			this.periodCategories = periodCategories;
		},

		loadCourseSectionPerson(courseSectionPerson) {
			this.courseSectionPerson = courseSectionPerson;
		},

		customShadowAdditional(color) {
			let customBoxShadow = color.match(/\d+/g);
			return `rgba(${customBoxShadow[0]}, ${customBoxShadow[1]}, ${customBoxShadow[2]}, .70)`;
		},

		inputSearch(val) {
			this.selPerson = val;
			this.picker = false;
		},

		checkEmpty(obj) {
			return Object.keys(obj).length === 0 && obj.constructor === Object;
		},

		async getAdditionalTeachersByGroup() {
			await this.$api
				.getAdditionalTeachersByGroup(this.selGroup, this.selPerson.id, this.selPeriod)
				.then((response) => {
					this.groupTeachers = response;
					/* console.log(this.groupTeachers) */
				});
		},

		async personDetail() {
			await this.$api.getPersonDetail(this.person).then((response) => {
				this.selPerson = response;
				if (this.rol == 1) {
					this.selPerson.typeName = 'estudiante';
				} else if (this.rol == 2) {
					this.selPerson.typeName = 'profesor';
				}
			});
		},

		onYearChange() {
			this.selPeriod = '';
			this.selGroup = null;
			this.trigger = false;
			this.search = false;
			this.academicSchedule = [];
			this.originalAcademicSchedule = [];
		},

		onPeriodChange() {
			this.trigger = false;
			this.search = false;
			this.selGroup = null;
			this.academicSchedule = [];
			this.originalAcademicSchedule = [];
		},

		onCourseChange() {},

		changeTab(tab) {
			this.showYearPeriodPicker = true;
			this.academicSchedule = [];
			this.originalAcademicSchedule = [];
			this.selPerson = {};
			this.selGroup = null;
			this.trigger = false;
			this.search = false;

			if (tab == 1 || tab == 2) {
				this.picker = true;
			} else {
				this.picker = false;
			}
		},

		getSchedule() {
			if (!this.dataAvailable && this.selectedTab != 3 && this.selectedTab != 4 && this.selectedTab != 5) {
				alert(this.$t('AcademicSchedule.select.person'));
				return;
			}
			this.search = true;
			switch (this.selectedTab) {
				case 1:
					this.getScheduleByStudent();
					break;
				case 2:
					this.getScheduleByTeacher();
					break;
				case 3:
					this.getScheduleByGroup();
					break;
				case 4:
					this.getScheduleByCourseSection();
					break;
				case 5:
					this.getScheduleByCourse();
					break;
				default:
					this.showYearPeriodPicker = true;
					break;
			}
		},

		doClickPersonItem() {
			if (this.rol == 0) {
				this.picker = !this.picker;
				this.selPerson = {};
				this.selPeriod = null;
				this.selGroup = null;
				this.academicSchedule = [];
				this.originalAcademicSchedule = [];
				this.trigger = false;
			}
		},

		doClickGroupItem() {
			this.picker = !this.picker;
			this.academicSchedule = [];
			this.originalAcademicSchedule = [];
			this.trigger = false;
			this.search = false;
			this.selGroup = null;
			this.selPeriod = null;
		},

		doClickCourseSectionItem() {
			this.picker = !this.picker;
			this.academicSchedule = [];
			this.originalAcademicSchedule = [];
			this.trigger = false;
			this.search = false;
			this.selCourseSection = null;
			this.selPeriod = null;
		},

		doClickCourseItem() {
			this.picker = !this.picker;
			this.academicSchedule = [];
			this.originalAcademicSchedule = [];
			this.trigger = false;
			this.search = false;
			this.selCourse = null;
			this.selPeriod = null;
		},

		async getScheduleByStudent() {
			this.datos.yearId = this.selYear.id;
			this.datos.periodId = this.selPeriod;
			this.datos.optionalSubjects = this.internalOptionalSubjects ? 1 : 0;

			let personId = this.selPerson.id;

			if (this.datos.yearId == null || this.datos.periodId == null) {
				return;
			}

			let retval = [];

			await this.$api
				.getScheduleByStudent(personId, this.datos)
				.then((response) => {
					response.forEach((event) => {
						var reg = new Date();
						let eventName = {
							title: event.group.course.subject.name + ' - ' + event?.group?.teacher?.firstname + ' ' + event?.group?.teacher?.lastname || null,
							titleSecondary: event.group.name,
							start: reg.getFullYear() + '-' + (reg.getMonth() + 1) + '-' + event.weekday + ' ' + (event.hour ? event.hour.start_time : event.start_time),
							end: reg.getFullYear() +'-' + (reg.getMonth() + 1) + '-' + event.weekday + ' ' + (event.hour ? event.hour.end_time : event.end_time),
							weekday: event.weekday == '7' ? '0' : event.weekday,
							image: event.group.teacher.avatar,
							classroom: event.classroom,
							group: event.group,
							period: event.period.id,
							person: personId,
							personType: 'student',
							defaultBackgroundColor: this.defaultColor,
							year: event.period.year,
							id: event.id,
						};
						retval.push(eventName);
					});

					this.academicSchedule = retval;
					this.originalAcademicSchedule = retval;

					if (this.academicSchedule.length == 0) {
						this.search = false;
						this.trigger = true;
					} else {
						this.search = false;
					}
				});
		},

		async getScheduleByTeacher() {
			this.datos.yearId = this.selYear.id;
			this.datos.periodId = this.selPeriod;
			this.datos.optionalSubjects = this.internalOptionalSubjects ? 1 : 0;

			let personId = this.selPerson.id;

			if (this.datos.yearId == null || this.datos.periodId == null) {
				return;
			}
			let retval = [];

			await this.$api
				.getScheduleByTeacher(personId, this.datos)
				.then((response) => {
					response.forEach((event) => {
						var reg = new Date();
						let eventName = {
							title: event.group.course.subject.name + ' - ' + event?.group?.teacher?.firstname + ' ' + event?.group?.teacher?.lastname || null,
							titleSecondary: event.group.name,
							start: reg.getFullYear() + '-' + (reg.getMonth() + 1) + '-' + event.weekday + ' ' + (event.hour ? event.hour.start_time : event.start_time),
							end: reg.getFullYear() + '-' + (reg.getMonth() + 1) + '-' + event.weekday + ' ' + (event.hour ? event.hour.end_time : event.end_time),
							weekday: event.weekday == '7' ? '0' : event.weekday,
							classroom: event.classroom,
							group: event.group,
							period: event.period,
							person: personId,
							personType: 'teacher',
							defaultBackgroundColor: this.defaultColor,
							year: event?.period?.year,
							id: event.id,
							additional: event.additional ? true : false,
						};

						retval.push(eventName);
					});

					this.academicSchedule = retval;
					this.originalAcademicSchedule = retval;

					if (this.academicSchedule.length == 0) {
						this.search = false;
						this.trigger = true;
					} else {
						this.search = false;
					}
				});
		},

		async getScheduleByGroup() {
			this.datos.yearId = this.selYear.id;
			this.datos.periodId = this.selPeriod;
			this.datos.teacherId = this.selPerson ? this.selPerson.id : null;
			this.datos.optionalSubjects = this.internalOptionalSubjects ? 1 : 0;

			if (this.datos.yearId == null || this.datos.periodId == null) {
				return;
			}
			let retval = [];

			await this.$api
				.getScheduleByGroup(this.selGroup, this.datos)
				.then((response) => {
					response.forEach((event) => {
						var reg = new Date();
						let eventName = {
							title: event.group.course.subject.name + ' - ' + event?.group?.teacher?.firstname + ' ' + event?.group?.teacher?.lastname || null,
							titleSecondary: event.group.name,
							start: reg.getFullYear() + '-' + (reg.getMonth() + 1) + '-' + event.weekday + ' ' + (event.hour ? event.hour.start_time : event.start_time),
							end: reg.getFullYear() + '-' + (reg.getMonth() + 1) + '-' + event.weekday + ' ' + (event.hour ? event.hour.end_time : event.end_time),
							weekday: event.weekday == '7' ? '0' : event.weekday,
							classroom: event.classroom,
							group: event.group,
							period: event.period,
							teacher: event.group.teacher,
							defaultBackgroundColor: this.defaultColor,
							year: event?.period?.year,
							id: event.id,
						};
						retval.push(eventName);
					});
					this.academicSchedule = retval;
					this.originalAcademicSchedule = retval;

					if (this.academicSchedule.length == 0) {
						this.search = false;
						this.trigger = true;
					} else {
						this.search = false;
					}
				});
		},

		async getScheduleByCourseSection() {
			this.datos.yearId = this.selYear.id;
			this.datos.optionalSubjects = false;

			if (this.selPerson) {
				this.datos.teacherId = this.selPerson.id;
			}

			if (this.datos.yearId == null) {
				return;
			}

			if (this.selPeriod) {
				this.datos.periodId = this.selPeriod;
			}
			let retval = [];

			await this.$api
				.getScheduleByCourseSection(this.selCourseSection, this.datos)
				.then((response) => {
					response.forEach((event) => {
						var reg = new Date();
						let eventName = {
							title: event.group.course.subject.name + ' - ' + event?.group?.teacher?.firstname + ' ' + event?.group?.teacher?.lastname || null,
							titleSecondary: event.group.name,
							start: reg.getFullYear() + '-' + (reg.getMonth() + 1) + '-' + event.weekday + ' ' + (event.hour ? event.hour.start_time : event.start_time),
							end: reg.getFullYear() + '-' + (reg.getMonth() + 1) + '-' + event.weekday + ' ' + (event.hour ? event.hour.end_time : event.end_time),
							weekday: event.weekday == '7' ? '0' : event.weekday,
							classroom: event.classroom,
							group: event.group,
							course: event.group.course,
							teacher: event.group.teacher,
							period: event.period,
							defaultBackgroundColor: this.defaultColor,
							year: event?.period?.year,
							id: event.id,
						};

						retval.push(eventName);
					});
					this.academicSchedule = retval;
					this.originalAcademicSchedule = retval;

					if (this.academicSchedule.length == 0) {
						this.search = false;
						this.trigger = true;
					} else {
						this.search = false;
					}
				});
		},

		async getScheduleByCourse() {
			this.datos.yearId = this.selYear.id;
			this.datos.optionalSubjects = false;

			if (this.selPerson) {
				this.datos.teacherId = this.selPerson.id;
			}

			if (this.datos.yearId == null) {
				return;
			}

			if (this.selPeriod) {
				this.datos.periodId = this.selPeriod;
			}

			let retval = [];

			await this.$api
				.getScheduleByCourse(this.selCourse, this.datos)
				.then((response) => {
					response.forEach((event) => {
						var reg = new Date();
						let eventName = {
							title: event.group.course.subject.name + ' - ' + event?.group?.teacher?.firstname + ' ' + event?.group?.teacher?.lastname || null,
							titleSecondary: event.group.name,
							start: reg.getFullYear() + '-' + (reg.getMonth() + 1) + '-' + event.weekday + ' ' + (event.hour ? event.hour.start_time : event.start_time),
							end: reg.getFullYear() + '-' + (reg.getMonth() + 1) + '-' + event.weekday + ' ' + (event.hour ? event.hour.end_time : event.end_time),
							weekday: event.weekday == '7' ? '0' : event.weekday,
							classroom: event.classroom,
							group: event.group,
							course: event.group.course,
							teacher: event.group.teacher,
							period: event.period,
							defaultBackgroundColor: this.defaultColor,
							year: event?.period?.year,
							id: event.id,
						};

						retval.push(eventName);
					});
					this.academicSchedule = retval;
					this.originalAcademicSchedule = retval;

					if (this.academicSchedule.length == 0) {
						this.search = false;
						this.trigger = true;
					} else {
						this.search = false;
					}
				});
		},

		async getGroupDetails(groupId) {
			if (groupId) {
				await this.$api.getGroupDetails(groupId).then((response) => {
					this.groupInformation = response;
				});
			} else {
				this.doClickGroupItem();
			}
		},

		async getCourseSectionDetails(courseId) {
			if (courseId) {
				await this.$api.getCourseSectionDetails(courseId).then((response) => {
					this.courseSectionInformation = response[0];
				});
			} else {
				this.doClickCourseSectionItem();
			}
		},

		async getCourseDetails(courseId) {
			if (courseId) {
				await this.$api.getCourseDetails(courseId).then((response) => {
					this.courseInformation = response;
				});
			} else {
				this.doClickCourseItem();
			}
		},

		clickEventInfo(event) {
			this.$emit('event-info', event);
		},

		async clickEventDeleteClassroom(event) {

			if (window.confirm(this.$t('AcademicSchedule.confirm.delete.class') + ' ' + event.data.eventName + ' (' + event.data.groupName + ') ' + event.data.weekDayName + ' at ' + event.data.startHour + ' - ' + event.data.endHour)) {
				await this.$api
					.deleleteScheduleClass(event.data.group, event.data.period.id, {
						startHour: event.data.startHour,
						endHour: event.data.endHour,
						classroom: event.data.classroom,
						weekday: event.data.weekday,
						sessionId: event.data.sessionId,
					})
					.then((response) => {
						if (response) {
							this.refresh();
						} else {
							console.warn('Class could not be deleted!!!');
						}
					});
			}
		},

		async refresh() {
			await this.getSchedule();
		},

		async updateEventSession(event) {
			await this.$api.updateScheduleSession(event).then((response) => {
				if (response) {
					let temp = this.academicSchedule.filter((session) => {
						const newHour = session.start.split(' ');
						const newWeekday = newHour[0].split('-');
						const sourceWeekday = response.sourceWeekday == 7 ? 0 : response.sourceWeekday;
						const targetWeekday = response.targetWeekday == 7 ? 0 : response.targetWeekday;
						
						if (parseInt(session.group.id) == parseInt(response.groupId) && parseInt(session.period.id) == parseInt(response.periodId) && parseInt(session.weekday) == parseInt(sourceWeekday) && newHour[1] == response.sourceStart && parseInt(response.sessionId) == parseInt(session.id)) {
							session.start = newWeekday[0] + '-' + newWeekday[1] + '-' + parseInt(response.targetWeekday) + ' ' + response.targetStart + ':00';
							session.end = newWeekday[0] + '-' + newWeekday[1] + '-' + parseInt(response.targetWeekday) + ' ' + response.targetEnd + ':00';
							session.weekday = targetWeekday.toString();
						}
						
						return session;
					}, response);
					
					this.academicSchedule = temp;					
				} else {
					console.warn('Class could not be updated!!!');
				}
			});
		},
	},

	i18n: {
		es: {
			'AcademicSchedule.select.academic.year': 'Seleccione año académico',
			'AcademicSchedule.select.period': 'Seleccione periodo',
			'AcademicSchedule.select.group': 'Seleccione el grupo',
			'AcademicSchedule.select.course.section': 'Seleccione el curso',
			'AcademicSchedule.select.course': 'Seleccione el curso',
			'AcademicSchedyle.search.shedule': 'Buscar horario',
			'AcademicSchedule.searching.schedule': 'Buscando horario...',
			'AcademicSchedule.not.schedules': 'No existen horarios',
			'AcademicSchedule.select.person': 'Por favor busque una persona para ver su horario académico',
			'AcademicSchedule.classroom': 'Aula',
			'AcademicSchedule.area': 'Área',
			'AcademicSchedule.group': 'Grupo',
			'AcademicSchedule.course': 'Curso',
			'AcademicSchedule.course.category': 'Categoría',
			'AcademicSchedule.course.director': 'Director',
			'AcademicSchedule.subject': 'Materia',
			'AcademicSchedule.input.search': 'Buscar',
			'AcademicSchedule.label.student': 'Estudiante',
			'AcademicSchedule.label.teacher': 'Profesor',
			'AcademicSchedule.label.group': 'Grupo',
			'AcademicSchedule.label.section': 'Sección',
			'AcademicSchedule.label.course': 'Curso',
			'AcademicSchedule.label.background.images': 'Imágenes de fondo',
			'AcademicSchedule.label.current.time.mark': 'marca hora actual',
			'AcademicSchedule.message.no.period.found': 'no hay periodos con calendarios académicos asignados para el año',
			'AcademicSchedule.message.no.courses.found': 'no hay cursos con calendarios académicos asignados para el año',
			'AcademicSchedule.message.refresh.data': 'actualizar horario',
			'AcademicSchedule.filter': 'filtrar',
			'AcademicSchedule.confirm.delete.class': 'Realmente deseas eliminar está clase?',
			'AcademicSchedule.label.additional.teachers': 'Mis clases de suplente',
			'AcademicSchedule.label.optional.subjects': 'Mis clases opcionales',
		},
		en: {
			'AcademicSchedule.select.academic.year': 'Select academic year',
			'AcademicSchedule.select.period': 'Select period',
			'AcademicSchedule.select.group': 'Select academic group',
			'AcademicSchedule.select.course.section': 'Select course',
			'AcademicSchedule.select.course': 'Select course',
			'AcademicSchedyle.search.shedule': 'Search schedule',
			'AcademicSchedule.searching.schedule': 'Searching schedule...',
			'AcademicSchedule.not.schedules': 'There are no schedules',
			'AcademicSchedule.select.person': 'Please find a person to see their academic schedule',
			'AcademicSchedule.classroom': 'Classroom',
			'AcademicSchedule.area': 'Area',
			'AcademicSchedule.group': 'Group',
			'AcademicSchedule.course': 'Course',
			'AcademicSchedule.course.category': 'Category',
			'AcademicSchedule.course.director': 'Director',
			'AcademicSchedule.subject': 'Subject',
			'AcademicSchedule.input.search': 'Search',
			'AcademicSchedule.label.student': 'Student',
			'AcademicSchedule.label.teacher': 'Teacher',
			'AcademicSchedule.label.group': 'Group',
			'AcademicSchedule.label.section': 'Section',
			'AcademicSchedule.label.course': 'Course',
			'AcademicSchedule.label.background.images': 'background images',
			'AcademicSchedule.label.current.time.mark': 'current time mark',
			'AcademicSchedule.message.no.period.found': 'no periods found with academic calendars assigned for the year',
			'AcademicSchedule.message.no.courses.found': 'no courses found with academic calendars assigned for the year',
			'AcademicSchedule.message.refresh.data': 'update schedule',
			'AcademicSchedule.filter': 'filter',
			'AcademicSchedule.confirm.delete.class': 'Do you want to delete this class?',
			'AcademicSchedule.label.additional.teachers': 'Show my substitute lessons',
			'AcademicSchedule.label.optional.subjects': 'My optional classes',
		},
		de: {
			'AcademicSchedule.select.academic.year': 'Schuljahre auswählen',
			'AcademicSchedule.select.period': 'Semester auswählen',
			'AcademicSchedule.select.group': 'wähle die Gruppe',
			'AcademicSchedule.select.course.section': 'Klassenstufe auswählen',
			'AcademicSchedule.select.course': 'Klassenstufe auswählen',
			'AcademicSchedyle.search.shedule': 'Kalender suchen',
			'AcademicSchedule.searching.schedule': 'Kalender suchen...',
			'AcademicSchedule.not.schedules': 'Es gibt keine Stundenpläne',
			'AcademicSchedule.select.person': 'Bitte suchen Sie eine Person, um ihren akademischen Stundenplan einzusehen',
			'AcademicSchedule.classroom': 'Raum',
			'AcademicSchedule.area': 'Bereich',
			'AcademicSchedule.group': 'Gruppe',
			'AcademicSchedule.course': 'Klassenstufe',
			'AcademicSchedule.course.category': 'Kategorie',
			'AcademicSchedule.course.director': 'Klassenlehrer',
			'AcademicSchedule.subject': 'Gegenstand',
			'AcademicSchedule.input.search': 'Suche',
			'AcademicSchedule.label.student': 'Schüler',
			'AcademicSchedule.label.teacher': 'Lehrer',
			'AcademicSchedule.label.group': 'Gruppe',
			'AcademicSchedule.label.section': 'Abteilung',
			'AcademicSchedule.label.course': 'Klassenstufe',
			'AcademicSchedule.label.background.images': 'Hintergrundbilder',
			'AcademicSchedule.label.current.time.mark': 'Hinweis auf die aktuelle Uhrzeit',
			'AcademicSchedule.message.no.period.found': 'Es gibt keine Zeiträume mit zugeordneten akademischen Zeitplänen für dieses Schuljahr',
			'AcademicSchedule.message.no.courses.found': 'Es gibt keine Kurse mit zugeordneten akademischen Zeitplänen für dieses Schuljahr',
			'AcademicSchedule.message.refresh.data': 'Studienplan aktualisieren',
			'AcademicSchedule.filter': 'filter',
			'AcademicSchedule.confirm.delete.class': 'Möchten Sie diese Klasse entfernen?',
			'AcademicSchedule.label.additional.teachers': 'Ersatzunterricht anzeigen',
			'AcademicSchedule.label.optional.subjects': 'meine Wahlfächer',
		},
	},
};
</script>

<style lang="scss">
.card {
	color: #495057;
	box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
		0 1px 3px 0 rgba(0, 0, 0, 0.12);
	padding: 10px;
	margin: 10px;
}
.card:hover {
	box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
		0 2px 6px 2px rgba(60, 64, 67, 0.15);
}
.academic-schedule {
	-webkit-font-smoothing: subpixel-antialiased;
	.v3-person-picker {
		display: inline-block;
		padding: 5px 0px 5px 0px;
		z-index: 2 !important;
		width: 100%;
	}
	.v3-academic-year-period-group-picker {
		display: inline-block;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
	.v3-academic-schedule {
		margin-top: 10px;
		width: 100%;
		padding: 0 10px 10px 10px;
		z-index: 2;
	}
	.schedule-button {
		&.--transparent {
			outline: none;
			background: transparent !important;
			color: #444444;
			border: none;
		}
	}
	.v3-year-picker .ui-native {
		font-size: 16px;
	}
	.ui-drawer-trigger {
		cursor: pointer;
	}
}
.ui-drawer-trigger {
	position: -webkit-sticky;
	position: sticky;
	top: -12px;
	background: white;
	z-index: 11;
	cursor: pointer;
}
.ui-card .academic-schedule {
	overflow: hidden;
	text-overflow: ellipsis;
}
.options {
	display: grid;
	text-align: right;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently */
	font-size: .75rem;
	align-content: space-around;
    justify-content: space-between;
    align-items: center;
    justify-items: end;
}
.ui-item .item-body {
	font-size: 1em !important;
}
#refresh {
	flex-grow: 1;
	align-items: flex-start;
	line-height: 38px;
	text-align: end;
	align-items: center;
	padding: var(--ui-breathe);
}
.ui-popover-contents ui-card ui-z-1 {
	z-index: 9999 !important;
}
.ui-popover .ui-popover-contents {
	z-index: 9999 !important;
}
.ui-search {
	margin: 0px 20px 0px 0px;
	padding: 12px;
	font-size: 1em;
	color: rgba(0, 0, 0, 0.8);
	outline-color: #1d7cbf;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: var(--ui-radius);
	color: #666;
	background-color: #fcfcfc;
}
.ui-card {
	border: 0px solid !important;
	color: #5b5b5b;
	border-radius: var(--ui-radius);
	background-color: #ffffff;
}
.pickers {
	display: flex;
    align-items: center;
	gap: 5px;
	flex-wrap: wrap;
}
</style>
